
$(document).ready(function () {
  $('.slideshow-slick').slick({
    // slide: '.slideshow__wrapper--item',
    infinite: false,
    dots: false,
    // arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })
})
